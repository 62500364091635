import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CallToActionLink = styled(Link)`
  background: ${props => props.theme[props.themeColor] || props.theme.link};
  color: ${props => props.theme[props.themeTextColor] || props.theme.lightText};
  border: 0;
  padding: 10px 20px;
  font-size: 16pt;
  font-weight: bold;
  border-radius: 10px;

  &:hover {
    color: white;
    text-decoration: none;
  }

  div &:first-child {
    margin-right: 20px;
  }
`;

const Lander = styled.div`
  padding: 80px 0;
  text-align: center;

  p {
    color: ${props => props.theme.secondaryLabel}
  }

  div {
    padding-top: 20px;
  }
`;

// background: ${({ props, theme }) => theme[props.color || 'link']};

export default function Home() {
  const [notes, setNotes] = useState([]);
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      async function myOnLoad() {
        if (!user) {
          return;
        }

        try {
          const notes = await loadNotes();
          setNotes(notes);
        } catch (e) {
          onError(e);
        }

        setIsLoading(false);
      }

      myOnLoad();
    }, [user]
  );

  function loadNotes() {
    return API.get("notes", "/notes");
  }

  function renderNewLink() {
    return (
      <LinkContainer key="new" to="/notes/new">
        <ListGroupItem>
          <h4>
            <b>{"\uFF0B"}</b> Create a new note
          </h4>
        </ListGroupItem>
      </LinkContainer>
    );
  }

  function renderNoteSummary(note, _i) {
    return (
      <LinkContainer key={note.noteId} to={`/notes/${note.noteId}`}>
        <ListGroupItem header={note.content.trim().split("\n")[0]}>
          {"Created: " + new Date(note.createdAt).toLocaleString()}
        </ListGroupItem>
      </LinkContainer>
    );
  }

  function renderNotesList(notes) {
    return [{}].concat(notes).map((note, i) =>
      i === 0 ? renderNewLink() : renderNoteSummary(note, i)
    );
  }

  function renderLander() {
    return (
      <Lander>
        <h1>FL Auth</h1>
        <p>Simple note taking</p>
        <div>
          <CallToActionLink themeColor="brand_orange" to="/login">
            Login
          </CallToActionLink>
          <CallToActionLink themeColor="brand_blue" to="/signup">
            Signup
          </CallToActionLink>
        </div>
      </Lander>
    );
  }

  function renderNotes() {
    return (
      <div className="notes">
        <PageHeader>Your Notes</PageHeader>
        <ListGroup>
          {!isLoading && renderNotesList(notes)}
        </ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {user ? renderNotes() : renderLander()}
    </div>
  );
}
