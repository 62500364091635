import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled.button`
&&& {
  background: transparent;
  color: ${({ theme }) => theme.text};
  border: 0;
  font-size: 24px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 0;
}
  `;

const ThemeToggleButton = ({ theme, toggleTheme }) => {
    return (
        <Button onClick={toggleTheme} >
          {theme === 'light' ? "🌞" : "🌚"}
        </Button>
    );
};

ThemeToggleButton.propTypes = {
    theme: PropTypes.string.isRequired,
    toggleTheme: PropTypes.func.isRequired,
};

export default ThemeToggleButton;
