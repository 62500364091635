import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import { AppContext, AppState, LoginStatus } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import ErrorBoundary from "./components/ErrorBoundary";
import { onError } from "./libs/errorLib";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/GlobalStyles";
import { lightTheme, darkTheme } from "./components/Themes";
import { useDarkMode } from "./libs/darkMode";
import ThemeToggleButton from "./components/ThemeToggleButton";
import styled from "styled-components";

const StyledNavbar = styled(Navbar)`
  background: ${({ theme }) => theme.secondaryFill};
  border: none;
`;

const StyledNavbarBrand = styled(Navbar.Brand)`
  display: flex;
  align-items: center;
`;

// const StyledNavItem = styled.li`
const StyledNavItem = styled(NavItem)`
  background: transparent;

  &&& a {
    color: ${({ theme }) => theme.secondaryLabel};
  }

  &&&.active > a {
    background: ${({ theme }) => theme.tertiaryFill};
  }

  &&&:hover > a {
    color: ${({ theme }) => theme.label};
  }
`;

function App() {
  const history = useHistory();
  const [appContext, setAppContext] = useState(new AppState());
  const [theme, themeToggler, componentMounted] = useDarkMode();

  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  // const [isAuthenticating, setIsAuthenticating] = useState(true);
  // const [user, setUser] = useState(null);
  // const [signupResult, setSignupResult] = useState(null);

  useEffect(() => {
    myOnLoad();
    // eslint-disable-next-line
  }, []);

  async function myOnLoad() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setAppContext({ ...appContext, user: user, loginStatus: LoginStatus.LOGGED_IN });
    } catch (e) {
      if (e === "No current user" || e === "not authenticated") {
        setAppContext({ ...appContext, loginStatus: LoginStatus.LOGGED_OUT });
      } else {
        onError(e);
        setAppContext({ ...appContext, loginStatus: LoginStatus.ERROR, error: e });
      }
    }
  }

  async function handleLogout() {
    await Auth.signOut();
    appContext.user = null;
    appContext.loginStatus = LoginStatus.LOGGED_OUT;
    setAppContext(appContext);
    history.push("/");
  }

  function navbarHeader() {
    return (
      <Navbar.Header>
        <StyledNavbarBrand>
          <Link to="/">
            <img width="150px" src="fl-logo.svg" alt="Flying Logic logo"/>
          </Link>
        </StyledNavbarBrand>
        <Navbar.Toggle />
      </Navbar.Header>
    );
  }

  function authenticatedLinks() {
    return (
      <>
        <LinkContainer to="/settings">
          <StyledNavItem>Settings</StyledNavItem>
        </LinkContainer>
        <StyledNavItem onClick={handleLogout}>Logout</StyledNavItem>
      </>
    );
  }

  function unauthenticatedLinks() {
    return (
      <>
        <LinkContainer to="/signup">
          <StyledNavItem>Signup</StyledNavItem>
        </LinkContainer>
        <LinkContainer to="/login">
          <StyledNavItem>Login</StyledNavItem>
        </LinkContainer>
      </>
    );
  }

  function renderPage() {
    return (
      <div className="App container">
        {/* <p>
          {appContext.loginStatus}
        </p> */}
        <ErrorBoundary>
          <AppContext.Provider value={appContext}>
            <StyledNavbar fluid collapseOnSelect>
              {navbarHeader()}
              <Navbar.Collapse>
                <Nav pullRight>
                  {appContext.isLoggedIn ? authenticatedLinks() : unauthenticatedLinks()}
                  <LinkContainer to="">
                    <ThemeToggleButton theme={theme} toggleTheme={themeToggler} />
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </StyledNavbar>
            <Routes />
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    );
  }

  function renderError() {
    return (
      <div>
        Sorry, an error occurred: {appContext.error}
      </div>
    );
  }

  if(!componentMounted) {
    return null;
  }

  let result = null;

  switch (appContext.loginStatus) {
    case LoginStatus.UNKNOWN:
      break;
    case LoginStatus.LOGGED_OUT:
      result = renderPage();
      break;
    case LoginStatus.LOGGED_IN:
      result = renderPage();
      break;
    case LoginStatus.ERROR:
      result = renderError();
      break;
    default:
      appContext.error = "Oops.";
      result = renderError();
      break;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        {result}
      </>
    </ThemeProvider>
  );
}

export default App;
