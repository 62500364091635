import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    body {
      margin: 0;
      padding: 0;
      font-family: "Open Sans", sans-serif;
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.label};
      transition: all 0.25s linear;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
    }

    select.form-control,
    textarea.form-control,
    input.form-control {
      font-size: 16px;
    }

    input[type=file] {
      width: 100%;
    }

    .App {
      margin-top: 15px;
    }

    .App .navbar-brand {
        font-weight: bold;
    }
  `;
