import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { FormGroup } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { FormArea, FieldLabel, ThemedFormControl } from "../components/FormStyles";

export default function Login() {
  const { setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const u = await Auth.signIn(fields.email, fields.password);
      setUser(u);
    } catch(e) {
      setIsLoading(false);
      onError(e);
    }
  }

  return (
    <FormArea>
      <h1>Welcome back!</h1>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <FieldLabel>Email</FieldLabel>
          <ThemedFormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FieldLabel>Password</FieldLabel>
          <ThemedFormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </FormArea>
  );
}
