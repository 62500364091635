import React from "react";
import { Button, Glyphicon } from "react-bootstrap";
import PropTypes from 'prop-types';
import styled from "styled-components";

const ThemedButton = styled(Button)`
  &&& {
    background: ${props => props.disabled ? props.theme.tertiaryFill : props.theme.link};
    border: none;
    color: ${props => props.disabled ? props.theme.tertiaryLabel : props.theme.lightText};
    font-weight: bold;
  }

  &&&:hover {
    color: ${props => props.disabled ? props.theme.tertiaryLabel : 'white'};
  }

  .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }
`;

export default function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <ThemedButton
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
      {props.children}
    </ThemedButton>
  );
}

LoaderButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
