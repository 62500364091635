import { useContext, createContext } from "react";

export class LoginStatus {
  static get UNKNOWN() { return "unknown"; }
  static get LOGGED_OUT() { return "logged_out"; }
  static get LOGGED_IN() { return "logged_in"; }
  static get ERROR() { return "error"; }
}

export class AppState {
  constructor() {
    this.loginStatus = LoginStatus.UNKNOWN;
    this.user = null;
    this.error = null;
  }

  get isLoggedIn() {
    return this.loginStatus === LoginStatus.isLoggedIn;
  }
}

export const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}
