import React, { useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { CardElement, injectStripe } from "react-stripe-elements";
import LoaderButton from "./LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import PropTypes from "prop-types";
import "./BillingForm.css";

function BillingForm({ isLoading, onSubmit, ...props }) {
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    storage: ""
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);

  isLoading = isProcessing || isLoading;

  function validateForm() {
    return (
      fields.name !== "" &&
      fields.storage !== "" &&
      isCardComplete
    );
  }

  async function handleSubmitClick(event) {
    event.preventDefault();
    setIsProcessing(true);
    const { token, error } = await props.stripe.createToken({ name: fields.name });
    setIsProcessing(false);
    onSubmit(fields.storage, { token, error });
  }

  function storageGroup() {
    return (
      <FormGroup bsSize="large" controlId="storage">
        <ControlLabel>Storage</ControlLabel>
        <FormControl
          min="0"
          type="number"
          value={fields.storage}
          onChange={handleFieldChange}
          placeholder="Number of notes to store"
        />
      </FormGroup>
    );
  }

  function cardholderGroup() {
    return (
      <FormGroup bsSize="large" controlId="name">
        <ControlLabel>Cardholder’s name</ControlLabel>
        <FormControl
          type="text"
          value={fields.name}
          onChange={handleFieldChange}
          placeholder="Name on the card"
        />
      </FormGroup>
    );
  }

  function cardInfo() {
    return (
      <>
        <ControlLabel>Credit Card Info</ControlLabel>
        <CardElement
          className="card-field"
          onChange={e => setIsCardComplete(e.complete)}
          style={{
            base: { fontSize: "18px", fontFamily: '"Open Sans", sans-serif' }
          }}
        />
      </>
    );
  }

  function submitButton() {
    return (
      <LoaderButton
        block
        type="submit"
        bsSize="large"
        isLoading={isLoading}
        disabled={!validateForm()}
      >
        Purchase
      </LoaderButton>
    );
  }

  return (
    <form className="BillingForm" onSubmit={handleSubmitClick}>
      { storageGroup() }
      <hr />
      { cardholderGroup() }
      { cardInfo() }
      { submitButton() }
    </form>
  );
}

BillingForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired
};

export default injectStripe(BillingForm);
