import React, { useState } from "react";
import { useFormFields } from "../libs/hooksLib";
import { Auth } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { FormGroup } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { Link, useHistory } from "react-router-dom";
import { FormArea, FieldLabel, ThemedFormControl, ThemedHelpBlock } from "../components/FormStyles";

export default function SignupConfirm() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    confirmationCode: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const { signupResult } = useAppContext();
  const history = useHistory();

  function validateForm() {
    return fields.email.length > 0 &&
      fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      history.push("/login");
    } catch (e) {
      setIsLoading(false);
      onError(e);
    }
  }

  function renderForm() {
    console.log(JSON.stringify(signupResult));
    return (
      <FormArea>
        <h1>Confirm your email.</h1>
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <FieldLabel>Email</FieldLabel>
            <ThemedFormControl
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="confirmationCode" bsSize="large">
            <FieldLabel>Confirmation Code</FieldLabel>
            <ThemedFormControl
              type="tel"
              onChange={handleFieldChange}
              value={fields.confirmationCode}
            />
            <ThemedHelpBlock>Please check your email for the code.</ThemedHelpBlock>
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Verify
        </LoaderButton>
          <p>
            <Link to="/signup-confirm-resend">
              I didn’t receive my code.
          </Link>
          </p>
        </form>
      </FormArea>
    );
  }

  return (
    <div className="SignupConfirm">
      {renderForm()}
    </div>
  );
}
