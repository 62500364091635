const dev = {
  STRIPE_KEY: "pk_test_wSzMHjMC4FOkb1uw3rFPCyqR00hh2ZkuhE",
  RECURLY_KEY: "ewr1-JVtxAngEwCvB34HTjFfAUm",
  s3: {
    REGION: "us-east-1",
    BUCKET: "fl-auth-2-api-dev-attachmentsbucket-1cx2j520tz2rv"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.flyinglogic.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_FOuW49jOE",
    APP_CLIENT_ID: "17lpjd3ob70pa64mr1fqvhrgjf",
    IDENTITY_POOL_ID: "us-east-1:8c93da77-80de-4638-9cb5-f8d34d32a0d0"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_wSzMHjMC4FOkb1uw3rFPCyqR00hh2ZkuhE",
  s3: {
    REGION: "us-east-1",
    BUCKET: "fl-auth-2-api-prod-attachmentsbucket-183tq49lc07q6"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.flyinglogic.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Q0bGdwG9r",
    APP_CLIENT_ID: "487og1aqashvlcj52kcu2j9ams",
    IDENTITY_POOL_ID: "us-east-1:cbbc3f3e-ed25-4078-a34b-14d7530e4c02"
  }
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
