const commonTheme = {
  brand_blue: '#0f7dff',
  brand_orange: '#fc7008',

  lightText: '#ffffff99',
  darkText: '#000',
};

export const lightTheme = {
  ...commonTheme,

  red: '#ff3b30',
  green: '#34c759',
  blue: '#007aff',
  indigo: '#5856d6',
  orange: '#ff9500',
  pink: '#ff2d55',
  purple: '#af52de',
  teal: '#5ac8fa',
  yellow: '#ffcc00',

  gray: '#8e8e93',
  gray2: '#aeaeb2',
  gray3: '#c7c7cc',
  gray4: '#d1d1d6',
  gray5: '#e5e5ea',
  gray6: '#f2f2f7',

  label: '#000',
  secondaryLabel: '#3c3c4399',
  tertiaryLabel: '#3c3c4377',
  quaternaryLabel: '#3c3c432d',

  placeholderText: '#3c3c434c',
  link: '#007aff',

  separator: '#3c3c4349',
  opaqueSeparator: '#c6c6c8ff',

  fill: '#78788033',
  secondaryFill: '#78788028',
  tertiaryFill: '#7676801e',
  quaternaryFill: '#74748014',

  background: '#fff',
  secondaryBackground: '#f2f2f7',
  tertiaryBackground: '#fff',

  groupedBackground: '#f2f2f7',
  secondaryGroupedBackground: '#fff',
  tertiaryGroupedBackground: '#f2f2f7',
};

export const darkTheme = {
  ...commonTheme,

  red: '#ff453a',
  green: '#30d158',
  blue: '#0a84ff',
  indigo: '#5e5ce6',
  orange: '#ff9f0a',
  pink: '#ff375f',
  purple: '#bf5af2',
  teal: '#64d2ff',
  yellow: '#ffd60a',

  gray: '#8e8e93',
  gray2: '#636366',
  gray3: '#48484a',
  gray4: '#3a3a3c',
  gray5: '#2c2c2e',
  gray6: '#1c1c1e',

  label: '#fff',
  secondaryLabel: '#ebebf599',
  tertiaryLabel: '#ebebf577',
  quaternaryLabel: '#ebebf52d',

  placeholderText: '#ebebf54c',
  link: '#0984ffff',

  separator: '#54545899',
  opaqueSeparator: '#38383a',

  fill: '#7878805b',
  secondaryFill: '#78788051',
  tertiaryFill: '#7676803d',
  quaternaryFill: '#7676802d',

  background: '#222',
  secondaryBackground: '#1c1c1eff',
  tertiaryBackground: '#2c2c2eff',

  groupedBackground: '#000',
  secondaryGroupedBackground: '#1c1c1eff',
  tertiaryGroupedBackground: '#2c2c2eff',
};
