import * as Sentry from "@sentry/browser";
// import { process } from "node";

const isLocal = process.env.NODE_ENV === "development";

export function initSentry() {
  if(isLocal) {
    return;
  }

  Sentry.init({dsn: "https://3060175700ac44fda6efcfbd3b826fd3@o400593.ingest.sentry.io/5259150"});
}

export function logError(error, errorInfo = null) {
  if(isLocal) {
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

export function onError(error) {
  let errorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
  // API Errors
  } else if(error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  alert(message);
}
