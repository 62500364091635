import styled from "styled-components";
import { ControlLabel, FormControl, HelpBlock } from "react-bootstrap";

export const FormArea = styled.div`
  padding: 60px 0;

  form {
    margin: 0 auto;
    max-width: 320px;
  }

  h1 {
    text-align: center;
    padding-bottom: 20px;
  }

  form p {
    text-align: center;
    padding: 20px 0;
  }

  form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.secondaryLabel};
  }
`;

export const FieldLabel = styled(ControlLabel)`
  color: ${({ theme }) => theme.secondaryLabel};
`;

export const ThemedFormControl = styled(FormControl)`
  background: ${({ theme }) => theme.tertiaryBackground};
  border-color: ${({ theme }) => theme.gray};
  color: ${({ theme }) => theme.label};
`;

export const ThemedHelpBlock = styled(HelpBlock)`
  &&& {
    color: ${({ theme }) => theme.tertiaryLabel};
  }
`;

  // color: ${({ theme }) => theme.secondaryLabel};
